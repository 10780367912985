<template>
  <!--begin::Leave Listing-->
  <div class="leave-template">
    <PageHeaderCount
      moduleType="leave"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_leaves"
      countkey="leave_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'leave-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('leave:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_leaves
                          }}</template>
                          <template v-else>{{
                            item.leave_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template>
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    @click="create_holiday_dialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'leave',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="leave"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Leave"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow holiday-table"
          style="
            max-height: 100vh;
            position: relative;
            height: calc(100vh - 230px);
          "
        >
          <ListingTable
            v-if="false"
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!dataLoading">
                  <template v-if="!lodash.isEmpty(rowData)">
                    <tr v-for="(data, index) in rowData" :key="index" link>
                      <template v-for="cols in defaultColDefs">
                        <td
                          v-if="cols.visible"
                          :key="cols.id"
                          :class="{
                            'simple-table-td': !cols.checkbox,
                            'checkbox-width-limit': cols.checkbox,
                          }"
                          :style="{ 'max-width': cols.width }"
                          v-on:click="
                            !cols.checkbox
                              ? rowClicked(data)
                              : 'javascript:void(0)'
                          "
                        >
                          <template v-if="cols.checkbox">
                            <v-checkbox
                              dense
                              v-model="selectedRows"
                              v-bind:value="data.id"
                              color="cyan"
                              class="hide-margin"
                              hide-details
                            ></v-checkbox>
                          </template>
                          <template v-else-if="cols.field == 'barcode'">
                            <Barcode :barcode="data.barcode"></Barcode>
                          </template>
                          <template v-else-if="cols.field == 'engineer'">
                            <v-list-item-title
                              class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                              v-html="data.engineer.display_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                              v-html="data.engineer.user_email"
                            ></v-list-item-subtitle>
                            <v-list-item-subtitle
                              class="font-size-16 font-weight-500 custom-nowrap-ellipsis text--secondary"
                              v-html="data.engineer.phone_number"
                            ></v-list-item-subtitle>
                          </template>
                          <template v-else-if="cols.field == 'status_type'">
                            <CustomStatus
                              endpoint="leave/status"
                              small
                              :status="data.status"
                            ></CustomStatus>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center d-inline"
                                    color="cyan"
                                    text-color="white"
                                    label
                                    small
                                  >
                                    {{ lodash.upperCase(data.type) }}
                                  </v-chip>
                                </template>
                                <span>Leave Type</span>
                              </v-tooltip>
                            </p>
                          </template>
                          <template v-else-if="cols.field == 'dates'">
                            <div class="leave-listing">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="custom-status font-weight-600 custom-grey-border text-uppercase justify-center d-inline"
                                    color="cyan"
                                    text-color="white"
                                    label
                                    small
                                  >
                                    {{ lodash.upperCase(data.duration) }}
                                  </v-chip>
                                </template>
                                <span>Duration</span>
                              </v-tooltip>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Start Date: </b>
                                <template v-if="data.start_date">{{
                                  formatDate(data.start_date)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary"
                                    >No Start Date</em
                                  ></template
                                >&nbsp;
                                <template
                                  v-if="
                                    data.duration == 'half-day' &&
                                    data.start_time
                                  "
                                  >{{ formatTime(data.start_time) }}</template
                                >
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>End Date: </b>
                                <template v-if="data.end_date">{{
                                  formatDate(data.end_date)
                                }}</template>
                                <template v-else
                                  ><em class="text--secondary"
                                    >No End Date</em
                                  ></template
                                >&nbsp;
                                <template
                                  v-if="
                                    data.duration == 'half-day' && data.end_time
                                  "
                                  >{{ formatTime(data.end_time) }}</template
                                >
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'added_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.added_by)"
                              :data="data"
                            >
                              <template v-slot:display_name>
                                {{ data.added_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.created_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.added_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else-if="cols.field == 'updated_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.updated_by)"
                              :data="data"
                            >
                              <template v-slot:display_name>
                                {{ data.updated_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.modified_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.updated_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else>
                            <div v-html="printRowData(cols, data)"></div>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr v-else-if="!dataLoading">
                    <td :colspan="columnLength()" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no leave at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Leave</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow holiday-table"
              style="
                max-height: 90vh;
                position: relative;
                height: calc(100vh - 230px);
              "
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <DateRangePicker
                      v-model="dates"
                      prop_label="Duration Date Range"
                    >
                    </DateRangePicker>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Leave')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
      </template>
    </ListingTemplate>
    <CreateHoliday
      :drawer="create_holiday_dialog"
      @close="create_holiday_dialog = false"
    ></CreateHoliday>
  </div>
  <!--end::Leave Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";
import CreateHoliday from "@/view/pages/Holiday/CreateHoliday";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "leave-list",
  data() {
    return {
      create_holiday_dialog: false,
      exportLoading: false,
      dates: [],
      pageModule: "leave-listing",
      routeAPI: "leave",
      routeName: "leave",
      routeDetailName: "leave.detail",
      status: "all",
      statusList: [],
      moreActions: [
        /*{
          title: "Export Leave(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Approved",
          action: "approved",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Rejected",
          action: "rejected",
          icon: "mdi-check-all",
        },
      ],
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      },
    },
  },
  components: {
    PageHeaderCount,
    draggable,
    CustomStatus,
    DateRangePicker,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CreateHoliday,
  },
  methods: {
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "approved":
          _this.updateLeaveStatus(2);
          break;
        case "rejected":
          _this.updateLeaveStatus(3);
          break;
      }
    },
    updateLeaveStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leave/status",
          data: { leave: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.LeaveExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("leave");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Holiday",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Leave #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Engineer",
        field: "engineer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Status / Leave Type",
        field: "status_type",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 7,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
